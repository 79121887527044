import React, { Component } from "react"
import PropTypes from "prop-types"
import { FIRST_SYMBOM_EMPTY_SPACE } from "../../../../constants/regexp.constants"

class MessageField extends Component {
  constructor(props) {
    super(props)
    //      this.state = {
    //        isLongText: false
    //      };
  }

  handleType = ev => {
    const { value } = ev.target
    const { sendMessage, setValid, fieldName } = this.props

    if (value.length > 1000) {
      ev.target.blur()
      return false
    }

    if (!FIRST_SYMBOM_EMPTY_SPACE.test(value)) {
      sendMessage(value, fieldName)
      setValid(false, fieldName)
      return
    }

    sendMessage(value, fieldName)
    setValid(!!value, fieldName)
  }

  render() {
    const col = this.props.twoColumn ? "" : ""
    const errMargin = {
      margin: "0 0 40px 0",
    }
    return (
      <div className={"group " + col + " message"}>
        <textarea
          className="message-field"
          rows="7"
          placeholder=" "
          disabled={this.props.disabled}
          value={this.props.messageValue}
          onChange={this.handleType}
          name="about"
          type="text"
          required={this.props.isRequired}
          onKeyUp={this.props.checkText}
          ref={this.props.innerRef}
        />
        {/* className for label
            className={this.state.isLongText?'hide':null}
         */}
        <label>Your message*</label>
        <span>{this.props.errMessage}</span>
      </div>
    )
  }
}

MessageField.proptypes = {
  input: PropTypes.string,
  messageValue: PropTypes.string,
  sendMessage: PropTypes.func,
  disabled: PropTypes.bool,
}

MessageField.defaultProps = {
  messageValue: "",
}

export default React.forwardRef((props, ref) => (
  <MessageField innerRef={ref} {...props} />
))