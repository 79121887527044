import React, { Component, createRef } from "react"
import PropTypes from "prop-types"
import { sendEmail, sendHubSpotForm } from "../../../js/helper"
import LoadFile from "./form-components/upload-file"
import MessageField from "./form-components/message-field"
import EmailField from "./form-components/email-field"
import NameField from "./form-components/name-field"
import CompanyField from "./form-components/company-field"
import PhoneField from "./form-components/phone-field"
import Reaptcha from "reaptcha"
import Cookies from "js-cookie"
import SnackbarContext from "../../../contexts/SnackbarContext"

// import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

import {
  reCaptchaSiteKey,
  reCaptchaSecretKey,
  hubSpotContactFormId,
  hubSpotCookieName,
  hubSpotEstimateFormId,
} from "../../../js/config"

import ThankYouMessage from "../../Estimate/ThankYouMessage"

import "./ContactUsForm.scss"
import { FIRST_SYMBOM_EMPTY_SPACE } from "../../../constants/regexp.constants"

const FIELDS = {
  name: "nameField",
  email: "emailField",
  phone: "phoneField",
  message: "messageField",
  company: "companyField",
}

class FormField extends Component {
  constructor() {
    super()
    this.state = {
      email: "",
      name: "",
      message: "",
      phone: "",
      company: "",
      file: "",
      errMessageEmail: "",
      errMessagePhone: "",
      errMessageName: "",
      errMessageCompany: "",
      errMessageText: "",
      errMessageCaptcha: "",
      validate: {
        email: false,
        phone: false,
        name: false,
        message: false,
        company: false,
        captcha: false,
      },
      isMailSent: false,
      isWaiting: false,
    }
    this.validationInputs = this.validationInputs.bind(this)
    this.onVerifyCaptcha = this.onVerifyCaptcha.bind(this)
    this.inputRefs = {
      email: createRef(),
      phone: createRef(),
      name: createRef(),
      company: createRef(),
      message: createRef(),
      captcha: createRef(),
    }
  }

  scrollIntoFieldsErrors = () => {
    if (this.state.errMessageName) {
      this.scrollToRef(this.inputRefs.name)
      return
    }

    if (this.state.errMessageEmail) {
      this.scrollToRef(this.inputRefs.email)
      return
    }

    if (this.state.errMessagePhone) {
      this.scrollToRef(this.inputRefs.phone)
      return
    }

    if (this.state.errMessageCompany) {
      this.scrollToRef(this.inputRefs.company)
      return
    }

    if (this.state.errMessageText) {
      this.scrollToRef(this.inputRefs.message)
      return
    }

    if (this.state.errMessageCaptcha) {
      this.scrollToRef(this.inputRefs.captcha)
      return
    }
  }

  scrollToRef = ref => {
    if (!ref?.current) return
    // Scroll to first known error into view
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
  }

  validationInputs = e => {
    const { isWaiting } = this.state;
    e.preventDefault()
    if (isWaiting) {
      return false;
    }
    const snackbarData = {
      message: "No Internet Connection. Check your network and try again.",
      severity: "warning",
      autoHideDuration: 6000,
      anchorOrigin: { vertical: "top", horizontal: "right" },
    }
    if (!window.navigator.onLine)
      return this.context?.handleOpenSnackbar({ ...snackbarData })

    let checkNameMessage = this.checkName(false)
    let checkCompanyMessage = this.checkCompany(false)
    let checkEmailMessage = this.checkEmail(false)
    let checkPhoneMessage = this.checkPhone(false)
    let checkTextMessage = this.checkText(false)
    let checkCaptchaMessage = ""
    if (this.props.visibleCaptcha) {
      checkCaptchaMessage = this.checkCaptcha(false)
    }
    const validate = this.state.validate

    this.setState(
      {
        errMessageEmail: checkEmailMessage,
        errMessagePhone: checkPhoneMessage,
        errMessageName: checkNameMessage,
        errMessageCompany: checkCompanyMessage,
        errMessageText: checkTextMessage,
        errMessageCaptcha: checkCaptchaMessage,
      },
      this.scrollIntoFieldsErrors
    )

    if (
      this.props.visibleCaptcha &&
      validate["captcha"] &&
      validate["phone"] &&
      validate["email"] &&
      validate["name"] &&
      validate["company"] &&
      validate["message"]
    ) {
      this.handleForm()
    }
  }

  onVerifyCaptcha = response => {
    if (response) {
      this.setState({
        errMessageCaptcha: "",
        validate: {
          ...this.state.validate,
          captcha: true,
        },
      })
    } else {
      this.messageError()
    }
  }

  // Change this function!
  messageError = ev => {
    const val = this.state.validate
    Object.keys(val).some(i => {
      return val[i]
    })
    if (!val["captcha"]) {
      this.setState({
        errMessageCaptcha: "Wrong Captcha",
      })
    }
    setTimeout(this.resetValidationStates, 10000)
  }

  checkPhone = (updateState = true) => {
    let errorMessage = ""
    const val = this.state.validate
    if (!val["phone"]) {
      const phone = this.state.phone
      if (!phone) {
        errorMessage = "Not a valid phone number"
      } else if (phone.length < 7 || phone.length > 21) {
        errorMessage = "Not a valid phone number"
      }
    }
    if (updateState) {
      this.setState({
        errMessagePhone: errorMessage,
      })
    }
    return errorMessage
  }

  checkEmail = (updateState = true) => {
    let errorMessage = ""
    const val = this.state.validate
    if (!val["email"]) {
      const email = this.state.email
      if (!email) {
        errorMessage = "Not a valid email"
      } else if (!FIRST_SYMBOM_EMPTY_SPACE.test(email)) {
        errorMessage = "The input cannot start with a blank space"
      } else {
        errorMessage = "Not a valid email"
      }
    }
    if (updateState) {
      this.setState({
        errMessageEmail: errorMessage,
      })
    }
    return errorMessage
  }

  checkCaptcha = (updateState = true) => {
    let errorMessage = ""
    const val = this.state.validate
    if (!val["captcha"]) {
      errorMessage = "Wrong Captcha"
    }

    if (updateState) {
      this.setState({
        errMessageCaptcha: errorMessage,
      })
    }
    return errorMessage
  }

  checkName = (updateState = true) => {
    let errorMessage = ""
    const val = this.state.validate

    if (!val["name"]) {
      const name = this.state.name
      if (!name) {
        errorMessage = "Not a valid name"
      } else if (!FIRST_SYMBOM_EMPTY_SPACE.test(name)) {
        errorMessage = "The input cannot start with a blank space"
      }
    }

    if (updateState) {
      this.setState({
        errMessageName: errorMessage,
      })
    }

    return errorMessage
  }

  checkCompany = (updateState = true) => {
    let errorMessage = ""
    const val = this.state.validate

    if (!val["company"]) {
      const company = this.state.company
      if (!company) {
        errorMessage = "Not a valid company name"
      } else if (!FIRST_SYMBOM_EMPTY_SPACE.test(company)) {
        errorMessage = "The input cannot start with a blank space"
      }
    }

    if (updateState) {
      this.setState({
        errMessageCompany: errorMessage,
      })
    }

    return errorMessage
  }

  checkText = (updateState = true) => {
    let errorMessage = ""
    const val = this.state.validate

    if (!val["message"]) {
      const message = this.state.message

      if (!message) {
        errorMessage = "Not a valid message"
      } else if (!FIRST_SYMBOM_EMPTY_SPACE.test(message)) {
        errorMessage = "The input cannot start with a blank space"
      }
    }

    if (updateState) {
      this.setState({
        errMessageText: errorMessage,
      })
    }
    return errorMessage
  }

  resetValidationStates = () => {
    const state = this.state.validate

    Object.keys(state).some(key => {
      if (state[key]) {
        this.setState({
          errMessageEmail: "",
          errMessagePhone: "",
          errMessageName: "",
          errMessageCompany: "",
          errMessageText: "",
          errMessageCaptcha: "",
        })
      }
    })
  }

  handleForm = async () => {
    let response
    let data = {
      email: this.state.email,
      name: this.state.name,
      company: this.state.company,
      message: this.state.message,
      phone: this.state.phone,
      url: typeof window !== "undefined" ? window?.location?.href : "",
    }
    const isBrowser = typeof window !== "undefined"
    const hutk = isBrowser ? Cookies.get(hubSpotCookieName) : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    data.form = 0
    if (this.props.isExtended && this.props.hasOwnProperty("data")) {
      data.form = 5
      data.position = this.props.data.title
    }
    this.setState({ isWaiting: true });
    response = await sendEmail(data, this.state.file, hubSpotContactFormId, {
      hutk: hutk,
      pageUri: pageUri,
      pageName: pageName,
    })
    this.setState({ isWaiting: false });
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    if (response) {
      if (this.state.file) this.setState({ file: "" })
      this.setState({ isMailSent: true })
    }
  }

  returnToForm = () => {
    this.setState({
      email: "",
      name: "",
      company: "",
      message: "",
      phone: "",
      file: "",
      errMessageEmail: "",
      errMessagePhone: "",
      errMessageName: "",
      errMessageCompany: "",
      errMessageText: "",
      errMessageCaptcha: "",
      isMailSent: false,
    })
  }

  sendFile = file => {
    this.setState({ file: file })
  }

  setText = (input, fieldName) => {
    switch (fieldName) {
      case FIELDS.message:
        this.setState({ message: input })
        break
      case FIELDS.email:
        this.setState({ email: input })
        break
      case FIELDS.name:
        this.setState({ name: input })
        break
      case FIELDS.company:
        this.setState({ company: input })
        break
      case FIELDS.phone:
        this.setState({ phone: input })
        break
    }
  }

  setValid = (isValid, fieldName) => {
    switch (fieldName) {
      case FIELDS.name:
        this.setState({
          validate: {
            ...this.state.validate,
            name: isValid,
          },
        })
        break
      case FIELDS.company:
        this.setState({
          validate: {
            ...this.state.validate,
            company: isValid,
          },
        })
        break
      case FIELDS.email:
        this.setState({
          validate: {
            ...this.state.validate,
            email: isValid,
          },
        })
        break
      case FIELDS.phone:
        this.setState({
          validate: {
            ...this.state.validate,
            phone: isValid,
          },
        })
        break
      case FIELDS.message:
        this.setState({
          validate: {
            ...this.state.validate,
            message: isValid,
          },
        })
        break
    }
  }
  changeWrong = state => {
    this.setState(state)
  }

  renderForm = status => {
    const { isWaiting } = this.state;
    const header =
      this.props.isExtended && this.props.hasOwnProperty("data") ? null : (
        <h1>{"We're here for you"}</h1>
      )
    const submitButtonClass = this.props.isExtended
      ? "btn-group sub-btn"
      : "btn-group col-md-12 col-sm-12"

    if (status) {
      return <ThankYouMessage />
    } else {
      return (
        <div
          className={
            "vacation-image-wrapper " +
            (this.props.isExtended && this.props.hasOwnProperty("data")
              ? null
              : "contacts-form-wrapper")
          }
        >
          {header}
          <form
            id={this.props.isExtended ? null : "hidden-form-header"}
            encType="multipart/form-data"
            onSubmit={this.validationInputs}
          >
            <div className="your-data">
              <NameField
                fieldName={FIELDS.name}
                nameValue={this.state.name}
                disabled={isWaiting}
                sendName={this.setText}
                setValid={this.setValid}
                checkName={this.checkName}
                errMessage={this.state.errMessageName}
                ref={this.inputRefs.name}
              />
              <EmailField
                fieldName={FIELDS.email}
                emailValue={this.state.email}
                disabled={isWaiting}
                sendEmail={this.setText}
                setValid={this.setValid}
                errMessage={this.state.errMessageEmail}
                checkEmail={this.checkEmail}
                ref={this.inputRefs.email}
              />
              <PhoneField
                fieldName={FIELDS.phone}
                phoneValue={this.state.phone}
                disabled={isWaiting}
                sendPhone={this.setText}
                setValid={this.setValid}
                errMessagePhone={this.state.errMessagePhone}
                checkPhone={this.checkPhone}
                ref={this.inputRefs.phone}
              />
              <CompanyField
                fieldName={FIELDS.company}
                companyValue={this.state.company}
                disabled={isWaiting}
                sendName={this.setText}
                setValid={this.setValid}
                checkName={this.checkCompany}
                errMessage={this.state.errMessageCompany}
                ref={this.inputRefs.company}
              />
            </div>
            <LoadFile
              file={this.state.file}
              disabled={isWaiting}
              sendFile={this.sendFile}
              title={this.props.title}
              wrongSize={this.state.wrongSize}
              wrongFormat={this.state.wrongFormat}
              setWrong={this.changeWrong}
            />
            <MessageField
              fieldName={FIELDS.message}
              messageValue={this.state.message}
              disabled={isWaiting}
              sendMessage={this.setText}
              setValid={this.setValid}
              errMessage={this.state.errMessageText}
              checkText={this.checkText}
              ref={this.inputRefs.message}
            />
            <div className="space_between_input"></div>
            <div className="your-message">
              <div className={submitButtonClass}>
                {this.props.visibleCaptcha && (
                  <div className="contact-captcha" ref={this.inputRefs.captcha}>
                    {isWaiting && (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                        }}
                      />
                    )}
                    <Reaptcha
                      sitekey={reCaptchaSiteKey}
                      onVerify={this.onVerifyCaptcha}
                      hl="en"
                      badge="bottomleft"
                    />
                    <span className="captcha-msg">
                      {this.state.errMessageCaptcha}
                    </span>
                  </div>
                )}
                <div className="submit-input">
                  <input
                    className="estimate-submit"
                    name="submit"
                    type="submit"
                    value={isWaiting ? "Sending..." : "GET IN TOUCH"}
                    id={this.props.isExtended ? null : "hidden-send"}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      )
    }
  }
  render = () => this.renderForm(this.state.isMailSent)
}

FormField.proptypes = {
  data: PropTypes.object,
  isExtended: PropTypes.bool.isRequired,
}
FormField.contextType = SnackbarContext
export default FormField
