import React from "react"
import SEO from "../components/CommonComponents/SEO"
import Layout from "../components/CommonComponents/Layout"
import ContactUsComponent from "../components/Contact/ContactUsComponent"
import { mainUrl } from "../js/config"

export default function Contact() {
  return (
    <section>
      <SEO
        title="Get In Touch With Expert Engineers — OS-System"
        description="Get in touch with expert web and software development engineers at OS-System company. Estimate your project or get a free quote from an experienced team of IT professionals."
        canonical={`${mainUrl}/contact/`}
      />
      <Layout showFormPoint={100}>
        <ContactUsComponent />
      </Layout>
    </section>
  )
}
