import React, { Component, createRef } from "react"
import PropTypes from "prop-types"
import resetImg from "../../../../images/cancelblue.svg"

class LoadFile extends Component {
  ref = createRef()
  loadFile = ev => {
    let fileOk = true
    const fileType = ev.target.files?.[0]?.type
    const acceptedTypesOfFiles = [
      "application/pdf", // PDF
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // DOCX
      "application/msword", // DOC
      "text/richtext", // RTF
      "application/rtf", // RTF
      "text/plain", // TXT
      "application/vnd.oasis.opendocument.text", // ODT
    ]

    if (!this.props.file) {
      let hasError = false
      if (ev.target.files[0].size > 10000000) {
        this.props.setWrong({ wrongSize: "wrong" })
        hasError = true
        fileOk = false
      } else {
        this.props.setWrong({ wrongSize: "" })
      }
      if (!acceptedTypesOfFiles.includes(fileType)) {
        this.props.setWrong({ wrongFormat: "wrong" })
        hasError = true
        fileOk = false
      } else {
        this.props.setWrong({ wrongFormat: "" })
      }

      if (hasError) {
        if (!this.ref?.current) return
        this.ref.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }

      if (fileOk) {
        this.props.sendFile(ev.target)
      }
    }
  }

  removeFile = e => {
    e.preventDefault()
    this.props.sendFile("")
  }

  uploadClickHandler = e => {
    e.preventDefault()
    const upload = document.getElementById("file-input")
    upload.click()
  }

  renderButton = file => {
    if (file) {
      const uploadedFile = `${this.props.file.files[0].name.substring(0, 8)}...`
      return (
        <div className="file_upload uploaded">
          <button
            className="nopointer"
            onClick={e => {
              e.preventDefault()
            }}
          >
            {uploadedFile}
            <img
              className="close_btn"
              onClick={this.removeFile}
              src={resetImg}
            />
          </button>
          <input
            value={this.props.file.value}
            disabled={this.props.disabled}
            id="file-input"
            style={{ transform: "scale(0)" }}
            className="file-input"
            accept=".pdf, .docx, .doc, .rtf, .txt, .odt"
            name="file"
            type="file"
          />
        </div>
      )
    } else {
      return (
        <div className="file_upload">
          <button
            id="btnAtached"
            disabled={this.props.disabled}
            onClick={this.uploadClickHandler}
          >
            {this.props.title}
            <span className="icon-upload">
              <i className="fa fa-upload download-arrow" aria-hidden="true"></i>
            </span>
          </button>
          <input
            value=""
            id="file-input"
            style={{ transform: "scale(0)" }}
            className="file-input"
            disabled={this.props.disabled}
            accept=".pdf, .docx, .doc, .rtf, .txt, .odt"
            onChange={this.loadFile}
            name="file"
            type="file"
          />
        </div>
      )
    }
  }

  render() {
    return (
      <div
        className={`btn-group upload-btn ${this.props.class}`}
        ref={this.ref}
      >
        {this.renderButton(this.props.file)}
        <div>
        <h5 className={`available-format ${this.props?.wrongFormat}`}>
          Valid formats: <em>.pdf, .docx, .doc, .rtf, .txt, .odt {this.props?.wrongFormat && '(file NOT attached)'}</em>
        </h5>
        <h5 className={`available-format ${this.props?.wrongSize}`}>
            Max size: <em>10 mb {this.props?.wrongSize && '(file NOT attached)'}</em>
        </h5>
        </div>
      </div>
    )
  }
}

LoadFile.proptypes = {
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  sendFile: PropTypes.func,
  disabled: PropTypes.bool,
}

export default LoadFile
