import React, { Component } from "react"
import PropTypes from "prop-types"
import { EMAIL_REGEXP } from "../../../../constants/regexp.constants"

class EmailField extends Component {
  constructor(props) {
    super(props)

    this.handleMail = this.handleMail.bind(this)
  }

  setEmail = ev => {
    const { value } = ev.target
    const { sendEmail, fieldName, checkEmail } = this.props
    sendEmail(value, fieldName)

    this.handleMail(ev)
  }

  handleMail = ev => {
    let isValid = false
    let currentInput = ev.target

    if (currentInput.value.length > 0) {
      if (EMAIL_REGEXP.test(currentInput.value.toLowerCase())) {
        isValid = true
      } else {
        isValid = false
      }
    }

    this.props.setValid(isValid, this.props.fieldName)
  }

  render() {
    const col = this.props.twoColumn ? "" : ""
    const { label, errMessage, emailValue, checkEmail, disabled } = this.props
    const errMargin = {
      margin: "0 0 40px 0",
    }
    return (
      <div
        className={`group email `}
        style={errMessage.length !== 0 ? errMargin : null}
      >
        <input
          placeholder=" "
          value={emailValue}
          disabled={disabled}
          name="email"
          type="text"
          onChange={this.setEmail}
          maxLength="48"
          onKeyUp={checkEmail}
          ref={this.props.innerRef}
        />
        <label>{label ? `${label}*` : "Email*"}</label>
        <span>{errMessage}</span>
      </div>
    )
  }
}

EmailField.proptypes = {
  input: PropTypes.string,
  disabled: PropTypes.bool,
  emailValue: PropTypes.string,
  sendEmail: PropTypes.func,
}

EmailField.defaultProps = {
  emailValue: "",
  disabled: false,
}

export default React.forwardRef((props, ref) => (
  <EmailField innerRef={ref} {...props} />
))
