import React from 'react';

import FormField from '../Form-field/Form-field';

import ContactUsMaps from '../ContactUsMaps';

export default class ContactUsComponent extends React.Component {
  state = {
    input: true
  }

  render() {
  
    const handleCaptcha = () => {
      this.setState(state => ({
      input: !state.input
    }))}
    return (
      <div className='contact-component'>
        <input id='skipcaptcha' style={{visibility:'hidden', height: 0 }} type='checkbox' onClick = {handleCaptcha} />
        <div className='' id='contacts-form' ref='contactsForm'>
          <div className=''>
            <div className='contact-style' id='contact-style'>
              <FormField visibleCaptcha={this.state.input} isExtended title={'Attach Files'} />
            </div>
          </div>
        </div>
        <div className='middleSpacer'></div>
        <div className='new-contacts-us'>
          <ContactUsMaps />
        </div>
      </div>
    )
  }
}
