import React, { Component } from "react"
import PropTypes from "prop-types"
import { FIRST_SYMBOM_EMPTY_SPACE } from "../../../../constants/regexp.constants"

class CompanyField extends Component {
  setName = ev => {
    const { value } = ev.target
    const { sendName, setValid, fieldName } = this.props
    if (value.length > 100) {
      ev.target.blur()
      return false
    }

    if (!FIRST_SYMBOM_EMPTY_SPACE.test(value)) {
      sendName(value, fieldName)
      setValid(false, fieldName)
      return
    }

    sendName(value, fieldName)
    setValid(!!value, fieldName)
  }

  render() {
    const col = this.props.twoColumn ? "" : ""
    const {
      label,
      errMessage,
      checkName,
      isRequired,
      companyValue,
      disabled,
    } = this.props
    const errMargin = {
      margin: "0 0 40px 0",
    }
    return (
      <div
        className={`group `}
        style={errMessage.length !== 0 ? errMargin : null}
      >
        <input
          placeholder=" "
          value={companyValue}
          name="company"
          type="text"
          disabled={disabled}
          required={isRequired}
          onChange={this.setName}
          onKeyUp={checkName}
          ref={this.props.innerRef}
          className="nameInput"
        />
        <label>{label ? `${label}*` : "Company name*"}</label>
        <span>{errMessage}</span>
      </div>
    )
  }
}

CompanyField.proptypes = {
  input: PropTypes.string,
  companyValue: PropTypes.string,
  disabled: PropTypes.bool,
  sendName: PropTypes.func,
}

CompanyField.defaultProps = {
  companyValue: "",
  disabled: false,
}

export default React.forwardRef((props, ref) => (
  <CompanyField innerRef={ref} {...props} />
))
