import React, { Component, createRef } from "react"
import PropTypes from "prop-types"
import IntlTelInput from "react-bootstrap-intl-tel-input"

class PhoneField extends Component {
  constructor(props) {
    super(props)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }


  componentDidMount() {
    const phoneInput = document.querySelector(".form-control.phone-input")
    phoneInput.setAttribute("maxLength", 20)
    phoneInput.addEventListener('blur', this.handleBlur);
  }

  componentWillUnmount() {
    document
      .querySelector(".form-control.phone-input")
      .removeEventListener('blur', this.handleBlur)
  }

  handleBlur(event) {
    const value = event.target.value
    const label = document.querySelector(".intl-phone-input ~ label")
    if (value) {
      label.classList.add('notEmpty')
    } else {
      label.classList.remove('notEmpty')
    }
  }

  handleOnChange(value) {
    let isValid = false
    const inputValue = value.intlPhoneNumber

    inputValue.length <= 21 && inputValue.length >= 7
      ? (isValid = true)
      : (isValid = false)

    this.props.sendPhone(value.intlPhoneNumber, this.props.fieldName)
    this.props.setValid(isValid, this.props.fieldName)
    this.props.checkPhone()
  }

  render() {
    const { errMessagePhone } = this.props
    const errMargin = { margin: "0 0 40px 0" }
    return (
      <div
        className="group telephoneInput"
        style={errMessagePhone.length !== 0 ? errMargin : null} 
        ref={this.props.innerRef}
      >
        <IntlTelInput
          preferredCountries={["US", "GB"]}
          defaultCountry={"AQ"}
          defaultValue={""}
          disabled={this.props.disabled}
          onChange={this.handleOnChange}
          removeToken={""}
          valueInput={this.props.phoneValue}
          maxlength="20"
        />
        <span>{this.props.errMessagePhone}</span>
        <label>{this.props.label ? `${this.props.label}*` : "Phone*"}</label>
      </div>
    )
  }
}

PhoneField.proptypes = {
  input: PropTypes.string,
  phoneValue: PropTypes.string,
  disabled: PropTypes.bool,
  sendPhone: PropTypes.func,
  errMessagePhone: PropTypes.string,
  checkPhone: PropTypes.func,
  fieldName: PropTypes.string,
}

PhoneField.defaultProps = {
  errMessagePhone: "",
  phoneValue: "",
  disabled: false,
}

export default React.forwardRef((props, ref) => (
  <PhoneField innerRef={ref} {...props} />
))
